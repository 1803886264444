import AdminAutomationAPI from 'api/AdminAutomationAPI';
import LookUpAPI from 'api/LookUpsAPI';
import classNames from 'classnames';
import Button from 'components/common/Button';
import Dropdown from 'components/common/Dropdown';
import Input from 'components/common/Input';
import MultiSelect from 'components/common/MultiSelect';
import TextArea from 'components/common/TextArea';
import * as _ from 'lodash';
import { SetStateAction, useEffect, useState } from 'react';
import { toast } from 'react-toastify';

const variableOptions = [
  { value: 'Button: Schedule appointment', label: 'Button: Schedule appointment' },
  // { value: 'Button: Order labs', label: 'Button: Order labs' },
  { value: 'Variable: Patient Firstname', label: 'Variable: Patient First name' },
  { value: 'Variable: Patient Lastname', label: 'Variable: Patient Last name' },
  { value: 'Variable: Physician Firstname', label: 'Variable: Physician First name' },
  { value: 'Variable: Physician Lastname', label: 'Variable: Physician Last name' },
  { value: 'Variable: Nearest Upcoming Appointment Date', label: 'Variable: Nearest Upcoming Appointment Date' },
  { value: 'Variable: Nearest Upcoming Appointment Time', label: 'Variable: Nearest Upcoming Appointment Time' },
];
const alertBasedOnOptions = [
  { value: 'createdAt', label: 'Created date' },
  { value: 'dueDate', label: 'Due date' },
];

const typeOptionsInternal = [
  { value: 'Task', label: 'Task' },
  { value: 'Notification', label: 'Notification' },
];

const typeOptionsPatient = [
  { value: 'Webhook', label: 'Webhook' },
  { value: 'Patient Message', label: 'Patient Message' },
];

const webhookTypeOptions = [
  { value: 'Campaign', label: 'Campaign' },
  { value: 'Canvas', label: 'Canvas' },
];
const triggerOptions = [
  { value: 'PH', label: 'PH' },
  { value: 'NP', label: 'NP' },
  { value: 'MA', label: 'MA' },
];

const timingUnitsOptions = [
  { value: 'before', label: 'Hours (before)' },
  { value: 'after', label: 'Hours (after)' },
  { value: 'minbefore', label: 'Mins (before)' },
  { value: 'minafter', label: 'Mins (after)' },
];

const buttonOptions = [
  { value: 'Start appointment', label: 'Start Appointment' },
  { value: 'View task', label: 'View Task' },
];

const statusOptions = [
  { value: 'active', label: 'Active' },
  { value: 'inactive', label: 'Inactive' },
];

const eventTypesOptions = [
  { value: 'Appointment Confirmation', label: 'Appointment Confirmation' },
  { value: 'Appointment Reschedule', label: 'Appointment Reschedule' },
  { value: 'Appointment Missed', label: 'Appointment Missed' },
  { value: 'Appointment Reminder', label: 'Appointment Reminder' },
  { value: 'Message: Responded', label: 'Message: Responded' },
  { value: 'Front Desk: Responded', label: 'Front Desk: Responded' },
  { value: 'Appointment Cancelation', label: 'Appointment Cancelation' },
];

export interface FormProps {
  handleFormSubmit?: (data: any) => void;
  handleEditedFormSubmit?: (data: any, id: string) => void;
  handleDelete?: (id: string) => void;
  hasDeleteAccess?: boolean;
  formData?: any;
  isEditMode?: any;
  automationType?: string;
}

const Form = ({
  handleFormSubmit,
  handleEditedFormSubmit,
  handleDelete,
  hasDeleteAccess,
  formData,
  isEditMode,
  automationType,
}: FormProps) => {
  const [name, setName] = useState('');
  const [type, setType] = useState<{ label: string; value: string } | null>();
  const [webhookType, setWebhookType] = useState<{ label: string; value: string } | null>();
  const [note, setNote] = useState('');
  const [message, setMessage] = useState('');
  const [text, setText] = useState('');
  const [audience, setAudience] = useState<any>();
  const [timingUnits, setTimingUnits] = useState<{ label: string; value: string } | null>();
  const [timingAmount, setTimingAmount] = useState('');
  const [status, setStatus] = useState<{ label: string; value: string } | null>();
  const [category, setCategory] = useState<{ label: string; value: string } | null>();
  const [categoryOptions, setCategoryOptions] = useState<{ label: string; value: string }[]>();
  const [triggerToOptions, setTriggerToOptions] = useState<{ label: string; value: string }[]>();
  const [variables, setVariables] = useState<{ label: string; value: string } | null>();
  const [variablesOptions, setVariablesOptions] = useState<{ label: string; value: string }[]>();
  const [button, setButton] = useState<{ label: string; value: string } | null>();
  const [buttonOptions, setButtonOptions] = useState<{ label: string; value: string }[]>();
  const [trigger, setTrigger] = useState<{ label: string; value: string } | null>();
  const [timingUnitOptions, setTimingUnitOptions] = useState<{ label: string; value: string }[]>();
  const [alertBasedOn, setAlertBasedOn] = useState<{ label: string; value: string } | null>();
  const [eventType, setEventType] = useState<{ label: string; value: string } | null>();
  const [webhookTypeId, setWebhookTypeId] = useState('');

  const handleTriggerSelection = (e: any) => {
    console.log('Trigger MultiSelect');
    console.log(e);
    setAudience(() => e.map((item: any) => item.value));
    setTrigger(e);
  };

  const handleCategorySelection = (e: any) => {
    setCategory(e);
    setTrigger(null);
  };

  const handleSubmit = (e: any) => {
    e.preventDefault();
    console.log('Submitted');
    const timeInHours = timingUnits?.value?.includes('min') ? (Number(timingAmount) / 60).toFixed(2) : timingAmount;
    const data = {
      name,
      triggerType: type?.value,
      triggerTime: {
        occurenceOrder: timingUnits?.value?.includes('before') ? 'before' : 'after',
        timeInHours: Number(timeInHours),
      },
      ...(alertBasedOn?.value && { criterionField: alertBasedOn?.value }),
      ...(type?.value === 'Webhook' && { webhookType: webhookType?.value }),
      ...(type?.value === 'Webhook' && { webhookTypeId: webhookTypeId }),
      ...(type?.value === 'Webhook' && { eventType: eventType?.value }),
      ...(type?.value === 'Task' && { note }),
      ...(type?.value === 'Task' && { categoryName: category?.value }),
      ...(type?.value === 'Task' && { audience }),
      ...(type?.value === 'Patient Message' && { message }),
      ...(type?.value === 'Notification' && { text }),
      ...(type?.value === 'Notification' && { buttons: [{ variable: button?.value, label: button?.label }] }),
      status: status?.value,
    };
    console.log(data);
    isEditMode ? handleEditedFormSubmit?.(data, formData._id) : handleFormSubmit?.(data);
  };

  const handleAlertBasedOn = (e: any) => {
    setAlertBasedOn(e);
    console.log('Alert Base on Event', e);
    if (e.value === 'createdAt') {
      const modifiedTimingUnitsList = timingUnitsOptions.filter((item: any) => item?.value?.includes('after'));
      console.log('modified Alert Unit List', modifiedTimingUnitsList);
      setTimingUnitOptions(modifiedTimingUnitsList);
    } else {
      setTimingUnitOptions(timingUnitsOptions);
    }
  };

  const handleEventType = (e: any) => {
    setEventType(e);
    if (e.value === 'Appointment Reminder') {
      const modifiedTimingUnitsListBefore = timingUnitsOptions.filter((item: any) => item?.value?.includes('before'));
      setTimingUnitOptions(modifiedTimingUnitsListBefore);
    } else if (e.value === 'Message: Responded' || e.value === 'Front Desk: Responded') {
      const modifiedTimingUnitsListAfter = timingUnitsOptions.filter((item: any) => item?.value?.includes('after'));
      setTimingUnitOptions(modifiedTimingUnitsListAfter);
    }
  };

  useEffect(() => {
    if (!_.isEmpty(formData)) {
      console.log('Form Data is', formData);
      const modifiedTimingUnitsList = timingUnitsOptions.filter((item: any) =>
        item?.value?.includes(formData?.triggerTime?.occurenceOrder),
      );
      setTimingUnitOptions(modifiedTimingUnitsList);
      setAlertBasedOn(alertBasedOnOptions?.find((item: any) => item.value === formData?.criterionField));
      setName(formData.name);
      setType(
        automationType == 'internal'
          ? typeOptionsInternal.find((item) => item.value === formData.triggerType)
          : typeOptionsPatient.find((item) => item.value === formData.triggerType),
      );
      setStatus(statusOptions.find((item) => item.value === formData.status));

      if (formData.triggerType === 'Webhook') {
        setWebhookType(webhookTypeOptions.find((item) => item.value === formData.webhookType));
        setEventType(eventTypesOptions.find((item) => item.value === formData.eventType));
        setWebhookTypeId(formData.webhookTypeId);
      } else if (formData.triggerType === 'Task') {
        setNote(formData.note);
        setCategory({ label: formData.categoryName, value: formData.categoryName });
        setTrigger(() => formData.audience.map((item: any) => ({ value: item, label: item })));
        setAudience(formData.audience);
      } else if (formData.triggerType === 'Patient Message') {
        setMessage(formData.message);
      } else if (formData.triggerType === 'Notification') {
        setText(formData.text);
      }

      if (formData.triggerTime?.timeInHours < 1) {
        const timeInMins = (formData.triggerTime.timeInHours * 60).toFixed();
        const timeUnit = formData.triggerTime.occurenceOrder.includes('before') ? 'minbefore' : 'minafter';
        setTimingAmount(timeInMins);
        setTimingUnits(timingUnitsOptions.find((item) => item.value === timeUnit));
      } else {
        setTimingAmount(formData.triggerTime?.timeInHours);
        setTimingUnits(timingUnitsOptions.find((item) => item.value === formData.triggerTime?.occurenceOrder));
      }
    }
  }, [formData]);

  useEffect(() => {
    setButton(buttonOptions?.find((item) => item.value === formData?.buttons?.[0]?.variable));
  }, [buttonOptions]);

  useEffect(() => {
    if (type) {
      if (type?.value === 'Task') {
        AdminAutomationAPI.fetchTaskCategories()
          .then((res) => {
            console.log('Fetch Catgories');
            if (res.data.errorCode === 0) {
              const options = res?.data?.data;
              const categories = options
                .filter((item: any) => item !== 'Appointment' && item !== 'Post-consult')
                .map((category: string) => ({
                  value: category,
                  label: category,
                }));
              console.log(categories);
              setCategoryOptions(categories);
            }
          })
          .catch((err) => {
            if (err?.response?.data?.message) {
              toast.error(err.response.data.message);
            } else toast.error('Failed to fetch Categories');
          });
      } else {
        const lookupParams = {
          type: type?.value === 'Notification' ? 'Notification' : 'Message',
        };
        console.log('LookupAPI type is', lookupParams);
        if (lookupParams) {
          LookUpAPI.fetchVariablesAndButtons(lookupParams)
            .then((res) => {
              if (res.data.errorCode === 0) {
                if (res?.data?.data?.variables) {
                  console.log('LookupAPI variables', res?.data?.data?.variables);
                  const variables = res.data.data?.variables;
                  const variableList = variables.map((v: any) => ({
                    label: v.label,
                    value: v.variable,
                  }));
                  setVariablesOptions(variableList);
                }
                if (res?.data?.data?.buttons) {
                  const buttons = res?.data?.data?.buttons;
                  const buttonsList = buttons.map((b: any) => ({
                    label: b.label,
                    value: b.variable,
                  }));
                  setButtonOptions(buttonsList);
                }
              }
            })
            .catch((err) => {
              if (err?.response?.data?.message) {
                toast.error(err.response.data.message);
              } else toast.error('Failed to fetch Variables');
            });
        }
      }
    }
  }, [type]);

  useEffect(() => {
    if (category) {
      const payload = {
        taskCategory: category?.value,
      };
      const initialAudience: SetStateAction<any[] | undefined> = [];
      AdminAutomationAPI.fetchUserTypeAccess(payload)
        .then((res) => {
          console.log('Fetch Trigger To Options');
          if (res.data.errorCode === 0) {
            const users = res?.data?.data;
            const triggerTos = users.map((user: any) => ({
              value: user.shortCode,
              label: user.shortCode,
            }));
            console.log(triggerTos);
            setTriggerToOptions(triggerTos);
            initialAudience[0] = triggerTos[0]?.value;
            if (!isEditMode) {
              // setTrigger(triggerTos[0]);
              setAudience(initialAudience);
            }
          }
        })
        .catch((err) => {
          if (err?.response?.data?.message) {
            toast.error(err.response.data.message);
          } else toast.error('Failed to fetch User Type Access');
        });
    }
  }, [category]);

  useEffect(() => {
    if (trigger) {
      console.log('Triggers are', trigger);
    }
  }, [trigger]);

  useEffect(() => {
    if (variables?.value) {
      console.log('Selected Variable: ', variables);
      if (type?.value === 'Patient Message') {
        setMessage((prevMessage) => `${prevMessage} [${variables?.value}]`);
      } else if (type?.value === 'Notification') {
        setText((prevText) => `${prevText} [${variables?.value}]`);
      }
    }
  }, [variables]);

  useEffect(() => {
    if (button?.value) {
      console.log('Selected Button: ', button);
      if (type?.value === 'Patient Message') {
        setMessage((prevMessage) => `${prevMessage} [${button?.value}]`);
      }
    }
  }, [button]);

  useEffect(() => {
    console.log('Button optins are', buttonOptions);
  }, [buttonOptions]);

  return (
    <form className="mt-10" onSubmit={handleSubmit}>
      <Input
        labelText="Name"
        wrapperClasses="mb-4"
        placeholder="Name"
        value={name}
        onChange={(e) => setName(e.target.value)}
      />
      <div className="mb-4">
        <Dropdown
          labelText="Type"
          placeholderText="Select Type"
          options={automationType == 'internal' ? typeOptionsInternal : typeOptionsPatient}
          value={type}
          onChange={(e) => setType(e)}
        />
      </div>
      {type?.value == 'Webhook' && (
        <>
          <div className="mb-4">
            <Dropdown
              labelText="Event Type"
              placeholderText="Select Event Type"
              options={eventTypesOptions}
              value={eventType}
              handleChange={handleEventType}
            />
          </div>
        </>
      )}
      {type?.value === 'Webhook' && (
        <div className="mb-4">
          <Dropdown
            labelText="Webhook type"
            placeholder="Select Webook type"
            options={webhookTypeOptions}
            value={webhookType}
            onChange={(e) => setWebhookType(e)}
          />
        </div>
      )}
      {type?.value === 'Webhook' && (
        <div className="mb-4">
          <Input
            labelText="Webhook type Id"
            wrapperClasses="mb-4"
            placeholder="Enter Webhook type Id"
            value={webhookTypeId}
            onChange={(e) => setWebhookTypeId(e.target.value)}
          />
        </div>
      )}
      {type?.value === 'Task' && (
        <div className="mb-4">
          <Dropdown
            labelText="Category"
            placeholder="Select Category"
            options={categoryOptions}
            value={category}
            onChange={handleCategorySelection}
          />
        </div>
      )}
      {type?.value === 'Task' && (
        <TextArea labelText="Note" name="note" value={note} handleChange={(e) => setNote(e.target.value)} />
      )}{' '}
      {type?.value === 'Patient Message' && (
        <TextArea labelText="Message" name="message" value={message} handleChange={(e) => setMessage(e.target.value)} />
      )}
      {type?.value === 'Notification' && (
        <TextArea labelText="Text" name="text" value={text} handleChange={(e) => setText(e.target.value)} />
      )}
      {type?.value !== 'Task' && type?.value !== 'Webhook' && (
        <div className="mb-4">
          <Dropdown
            labelText="Variables"
            placeholderText="Select Variables"
            options={variablesOptions}
            value={variables}
            onChange={(e) => setVariables(e)}
          />
        </div>
      )}
      {type?.value === 'Task' && (
        <div className="mb-4">
          <MultiSelect
            labelText="Trigger to"
            placeholder="Select Trigger to"
            options={triggerToOptions}
            value={trigger}
            handleChange={handleTriggerSelection}
          />
        </div>
      )}
      {type?.value !== 'Task' && type?.value !== 'Webhook' && (
        <div className="mb-4">
          <Dropdown
            name="buttons"
            labelText="Buttons"
            placeholderText="Select Buttons"
            options={buttonOptions}
            value={button}
            onChange={(e) => setButton(e)}
          />
        </div>
      )}
      {type?.value !== 'Webhook' && (
        <>
          <div className="mb-4">
            <Dropdown
              labelText="Trigger based on"
              placeholderText="Select Trigger Based On"
              // labelClasses="mr-14"
              // ownClasses="pl-5"
              options={alertBasedOnOptions}
              value={alertBasedOn}
              handleChange={handleAlertBasedOn}
            />
          </div>
        </>
      )}
      {eventType?.value !== 'Appointment Confirmation' &&
        eventType?.value !== 'Appointment Reschedule' &&
        eventType?.value !== 'Appointment Missed' &&
        eventType?.value !== 'Appointment Cancelation' && (
          <>
            <div className="mb-4">
              <Dropdown
                labelText="Timing (units)"
                placeholder="Select Timing Unit"
                options={timingUnitOptions}
                value={timingUnits}
                onChange={(e) => setTimingUnits(e)}
              />
            </div>
            <div className="mb-4">
              {timingUnits?.value?.includes('min') ? (
                <Input
                  type="number"
                  min="1"
                  max="60"
                  labelText="Timing (amount) (mins)"
                  placeholder="Enter Amount"
                  value={timingAmount}
                  onChange={(e) => setTimingAmount(e.target.value)}
                />
              ) : (
                <Input
                  type="number"
                  min="1"
                  step="0.1"
                  labelText="Timing (amount) (hrs)"
                  placeholder="Enter Amount"
                  value={timingAmount}
                  onChange={(e) => setTimingAmount(e.target.value)}
                />
              )}
            </div>
          </>
        )}
      <div className="mb-8">
        <Dropdown
          labelText="Status"
          placeholder="Select Status"
          options={statusOptions}
          value={status}
          onChange={(e) => setStatus(e)}
        />
      </div>
      <div
        className={classNames('flex items-center mt-4 pb-14', {
          'justify-end': !isEditMode,
          ' justify-between': isEditMode,
        })}
      >
        {isEditMode && hasDeleteAccess && (
          <Button
            ownClasses="rounded-full p-0 font-medium"
            type="depressed"
            onClick={() => handleDelete?.(formData._id)}
          >
            <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="red">
              <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M6 18L18 6M6 6l12 12" />
            </svg>
            Delete Trigger
          </Button>
        )}
        <Button ownClasses="rounded-full " type="success" formType>
          Save
        </Button>
      </div>
    </form>
  );
};

export default Form;
