import MessagePatientForm from 'components/Admin/Patient/Messages/MessagePatientForm';
import socket from 'socket/socket';
import { dateTimeFormatter } from 'utils/dateTimeFormatter';
import { ReactComponent as ArrowsSVG } from 'assets/icons/arrows-angle-expand.svg';
import { useSelector, RootStateOrAny } from 'react-redux';
import { toast } from 'react-toastify';
import { useCallback, useEffect, useRef, useState } from 'react';
import LoadingOverlay from 'react-loading-overlay-ts';
import socketFrontDesk from 'socket/socketFrontDesk';
import { ViewChart } from 'utils/ViewChart';
import classNames from 'classnames';
import { useHistory } from 'react-router-dom';
import { tabs } from 'pages/Admin/PatientSummary';
import { ChannelData } from 'redux/channels/interfaces';
import moment from 'moment';
import * as Sentry from '@sentry/react';
import { usePrevious } from 'react-use';
import { debounce } from 'lodash';
import { MessagesFromBackend } from 'components/Admin/Patient/Messages/message.types';

export interface MessageProps {
  channelList?: any;
  patientId?: string;
  taskId?: string;
  category?: string;
  assignedTo?: any;
  defaultChannelId?: any;
  status?: string;
  fetchChannelList: () => void;
  setmessageSent?: any;
  setmarkCompleteEnabled?: any;
}

const frontDesk = 'Front desk';

const pathToPatient = '/dashboard/patient-details';

const MessageSection: React.FC<MessageProps> = ({
  channelList,
  patientId,
  taskId,
  category,
  assignedTo,
  defaultChannelId,
  status,
  fetchChannelList,
  setmessageSent,
  setmarkCompleteEnabled,
}) => {
  const userInfo = useSelector((state: RootStateOrAny) => state.userProfile.userDetails);
  const [messageSend, setMessageSend] = useState(false);
  const [messages, setMessages] = useState<MessagesFromBackend[]>([]);
  const [oldMessages, setOldMessages] = useState([]);
  const [currentChannelId, setCurrentChannelId] = useState(
    defaultChannelId ||
      (category === frontDesk
        ? channelList.length !== 0 && channelList[0].channelId
        : channelList?.find(
            (channel: ChannelData) => channel.channelTitle.toLowerCase() === 'General health'.toLowerCase(),
          )?.channelId),
  );
  const [messageloading, setMessageLoading] = useState(false);

  const [scrollHeight, setScrollHeight] = useState(0);
  const [page, setPage] = useState(0);
  const messagesEndRef = useRef<HTMLDivElement>(null);
  const containerRef = useRef<HTMLDivElement>(null);
  const [isLargeView, setIsLargeView] = useState(false);
  const [isEmitMessages, setIsEmitMessages] = useState(false);
  const [messageViewLoader, setMessageViewLoader] = useState(false);
  const [channelAdded, setChannelAdded] = useState<any>(false);
  const prevChannelList = usePrevious(channelList);

  const history = useHistory();

  const onSendMessage = (channelId: string, channelMessage: string, buttons: any) => {
    setMessageSend(false);
    setMessageLoading(true);
    try {
      if (category === frontDesk) {
        socketFrontDesk.emit(
          'sendMessage',
          {
            message: channelMessage,
            taskId: taskId,
            receiverDetails: [{ id: patientId }],
            senderDetails: { id: userInfo._id, userType: userInfo.userType },
            channelId: channelId,
          },

          function (data: any) {
            if (data.status) {
              toast.success(data.message);
              setMessageSend(true);
              setmessageSent(true);
            } else {
              toast.error(data.message);
              setMessageSend(false);
            }
            setMessageLoading(false);
          },
        );
      } else {
        console.log(userInfo._id, 'user id');
        setmessageSent(true);
        socket.emit(
          'newMessageToServer',
          {
            message: channelMessage,
            taskId: taskId,
            receiverDetails: [{ id: patientId }],
            senderDetails: { id: userInfo._id, userType: userInfo.userType },
            channelId: channelId,
            buttons: buttons,
          },
          false,
          function (data: any) {
            if (data.status) {
              toast.success(data.message);
              setMessageSend(true);
            } else {
              toast.error(data.message);
              setMessageSend(false);
            }
            setMessageLoading(false);
          },
        );
      }
    } catch (e) {
      Sentry.captureException(e);
      toast.error('Unbale to send message, Please try again!');
      setMessageSend(false);
      setMessageLoading(false);
    }
  };

  const socketEmitMessage = (id: string, category?: string) => {
    setMessageViewLoader(true);
    if (category === 'Front desk') {
      try {
        socketFrontDesk.emit('joinRoom', id, (data: any) => {
          console.log('=======joined front desk channel==========');
        });
        socketFrontDesk.emit(
          'getOldMessage',
          {
            limit: 10,
            skip: 0,
            channelId: id,
          },
          (data: any) => {
            console.log(data, 'soketemmit');
          },
        );
        socketFrontDesk.emit('markSeen', {
          channelId: id,
          userId: userInfo._id,
        });
      } catch (e) {
        Sentry.captureException(e);
        console.log('error');
      }
    } else {
      try {
        socket.emit('joinRoom', id, (data: any) => {
          console.log('=======joined channel==========');
        });
        socket.emit('markSeen', {
          channelId: id,
          userId: userInfo._id,
        });
      } catch (e) {
        Sentry.captureException(e);
        console.log('error');
      }
      socket.emit(
        'getNextMessage',
        {
          limit: 10,
          skip: 0,
          channelId: id,
        },
        (data: any) => {
          console.log(data, 'soketemmit');
        },
      );
    }
  };

  const afterChannelCreation = () => {
    fetchChannelList();
    setChannelAdded(true);
  };

  useEffect(() => {
    if (messageSend) {
      socketEmitMessage(currentChannelId, category);
    }
  }, [messageSend]);

  useEffect(() => {
    if (category === 'Front desk' && currentChannelId) {
      socketFrontDesk.off('messageToClients');
      socketFrontDesk.on('messageToClients', () => {
        setMessageSend(true);
        setMessageLoading(false);
        setMessageViewLoader(true);
        socketFrontDesk.emit(
          'getOldMessage',
          {
            limit: 10,
            skip: 0,
            channelId: currentChannelId,
          },
          (data: any) => {
            console.log(data, 'soketemmit');
          },
        );
      });
    }
  }, [category, currentChannelId]);
  const scrollToBottom = () => {
    containerRef.current?.scrollTo({ top: messagesEndRef.current?.offsetTop });
  };

  useEffect(() => {
    if (currentChannelId) {
      setMessages([]);
      socketEmitMessage(currentChannelId);
    }
  }, [currentChannelId]);

  useEffect(() => {
    if (prevChannelList !== channelList)
      if (channelAdded) {
        setChannelAdded(false);
      } else if (defaultChannelId) {
        setCurrentChannelId(defaultChannelId);
      } else {
        setCurrentChannelId(
          defaultChannelId ||
            (category === frontDesk
              ? channelList.length !== 0 && channelList[0].channelId
              : channelList?.find(
                  (channel: ChannelData) => channel.channelTitle.toLowerCase() === 'General health'.toLowerCase(),
                )?.channelId),
        );
      }
  }, [defaultChannelId, channelList, channelAdded]);
  useEffect(() => {
    let data: MessagesFromBackend[] = [];
    const isMustReverse =
      moment((oldMessages[0] as MessagesFromBackend)?.messageStatus.sent).diff(
        moment(messages[0]?.messageStatus.sent),
      ) > 0;

    if (isMustReverse) {
      data = [...messages, ...oldMessages].filter((v, i, a) => a.findIndex((t) => t._id === v._id) === i);
    } else {
      data = [...oldMessages, ...messages].filter((v, i, a) => a.findIndex((t) => t._id === v._id) === i);
    }

    setMessages(data);
  }, [oldMessages]);

  useEffect(() => {
    try {
      socket.on('messageToClients', (data) => {
        setMessages((prevMessages) => {
          const newData = [...prevMessages, data].filter((v, i, a) => a.findIndex((t) => t._id === v._id) === i);
          return newData;
        });
        socketEmitMessage(currentChannelId);
      });
    } catch (err) {
      Sentry.captureException(err);
      console.log(err);
    }
  }, []);

  useEffect(() => {
    if (category && currentChannelId)
      if (category === 'Front desk')
        try {
          socketEmitMessage(currentChannelId, category);
          socketFrontDesk.on('messageHistory', (data) => {
            setMessageViewLoader(false);
            setmarkCompleteEnabled(true);
            if (data.data) {
              const isMustReverse =
                moment(data.data[0]?.messageStatus.sent).diff(
                  moment(data.data[data.data.length - 1]?.messageStatus.sent),
                ) > 0;

              if (isMustReverse) {
                setMessages(data.data.reverse());
              } else {
                setMessages(data.data);
              }
            }
          });
        } catch (err) {
          Sentry.captureException(err);
          console.log(err);
          setMessageViewLoader(false);
        }
      else
        try {
          socketEmitMessage(currentChannelId, category);
          socket.on('oldMessages', (data) => {
            setMessageViewLoader(false);
            setmarkCompleteEnabled(true);
            if (data?.[0]) {
              const isMustReverse =
                moment(data[0].messageStatus.sent).diff(moment(data[data.length - 1].messageStatus.sent)) > 0;
              if (isMustReverse) {
                setOldMessages(data.reverse());
              } else {
                setOldMessages(data);
              }
            }
          });
        } catch (err) {
          Sentry.captureException(err);
          console.log(err);
          setMessageViewLoader(false);
        }
  }, [category, currentChannelId]);

  const MAX_LOADED_ONCE_MESSAGES = 7;

  const getNextMessage = ({ channelId, limit, skip }: { channelId: string; limit: number; skip: number }) => {
    socket.emit('getNextMessage', {
      limit,
      skip,
      channelId,
    });
  };

  const debounceOnScroll = () => {
    const isScrollTop = containerRef?.current?.scrollTop === 0;
    if (!!containerRef?.current && isScrollTop) {
      setScrollHeight(containerRef.current.scrollHeight);
      const newPageCount = page + 1;

      setPage(newPageCount);
      getNextMessage({ channelId: currentChannelId, limit: 10, skip: newPageCount });
    }
  };

  const debouncedScrollTop = useCallback(debounce(debounceOnScroll, 1000), [getNextMessage]);

  const messagesWrapperClasses = classNames(
    'relative md:w-full flex flex-col overflow-y-auto',
    isLargeView ? (messages.length > MAX_LOADED_ONCE_MESSAGES ? 'h-80' : 'h-72') : 'h-36',
  );

  useEffect(() => {
    if (containerRef?.current && messages.length) {
      const scrollToY = containerRef.current.scrollHeight - scrollHeight;

      containerRef.current?.scrollTo(0, scrollToY);
    } else {
      scrollToBottom();
    }
  }, [messages]);

  const handleLargeView = () => {
    setIsLargeView((prevState) => !prevState);
  };

  const redirectToMessages = () => {
    history.push(`${pathToPatient}/${patientId}?active-tab=${tabs.messages}`);
  };

  const markSeenMessage = () => {
    console.log('on Focus');
  };

  return (
    <>
      <div className="relative py-12">
        <div className="flex flex-col md:flex-row mb-4">
          <div className="md:w-1/5 subheading mb-4">
            <h3 className="font-bold text-lg">Messages</h3>
            <h4
              className="font-bold text-inputSuccess underline cursor-pointer text-green 2xl:text-lg"
              onClick={redirectToMessages}
            >
              View all
            </h4>
          </div>
          <div className=" md:w-4/5 flex flex-col">
            <LoadingOverlay active={messageViewLoader} spinner>
              {messages.length ? (
                <div className={messagesWrapperClasses} ref={containerRef} onScroll={debouncedScrollTop}>
                  <div
                    className="sticky top-2 self-end mr-4 w-6 h-6 bg-white shadow-lg rounded-lg cursor-pointer"
                    onClick={handleLargeView}
                  >
                    <ArrowsSVG className=" w-full h-full p-1" />
                  </div>
                  {messages.map((message: any) => {
                    const isYourMessage = userInfo._id === message.senderDetails?.id;

                    const messageWrapperClasses = classNames(
                      'w-4/5 py-2 pl-3 pr-20 mb-3 rounded-lg',
                      isYourMessage ? 'self-end bg-lightGreen bg-opacity-50' : `self-start bg-lightGreyBackground`,
                    );

                    return (
                      <div className={messageWrapperClasses} key={message._id}>
                        <div className="flex mb-2">
                          <h4 className="mr-2 font-bold">{isYourMessage ? <>Me</> : message.senderDetails?.name}</h4>
                          <span className="text-myPlanLightGrey ">
                            {dateTimeFormatter(message.messageStatus?.sent)}
                          </span>
                        </div>
                        <p>{message.message}</p>
                        {message.filePath && message.fileName && (
                          <div
                            className={classNames({
                              'mt-3 sm:mt-4': message.message || !!message.buttons?.length,
                              '2xl:mt-8': message.message || !!message.buttons?.length,
                            })}
                          >
                            <div className={'sm:mx-5'}>
                              <a
                                className="overflow-hidden max-w-full"
                                href={message.filePath}
                                target="_blank"
                                rel="noreferrer"
                              >
                                <img src={message.filePath} alt={message.fileName} className={'h-20 max-w-max'} />
                              </a>
                            </div>
                          </div>
                        )}
                      </div>
                    );
                  })}

                  <div ref={messagesEndRef} />
                  {/* <span className="underline font-semibold cursor-pointer">[expand]</span> */}
                </div>
              ) : (
                'No Messages'
              )}
            </LoadingOverlay>
          </div>
        </div>
        {userInfo &&
          userInfo.userType.name !== 'Admin' &&
          assignedTo &&
          assignedTo._id === userInfo._id &&
          status !== 'Completed' && (
            <div className="flex flex-col md:flex-row">
              <h3 className="md:w-1/5 subheading mb-4">Message patient</h3>
              <div className=" md:w-4/5 flex flex-col">
                <LoadingOverlay active={messageloading} spinner>
                  {channelList && (
                    <MessagePatientForm
                      allowToPickChannel={true}
                      channelList={channelList}
                      onSendMessage={onSendMessage}
                      markSeenMessage={markSeenMessage}
                      messsageSend={messageSend}
                      patientId={patientId}
                      taskId={taskId}
                      defaultChannelId={currentChannelId}
                      doctorId={userInfo && userInfo._id}
                      channelCategory={category === frontDesk ? 'frontdesk' : 'message'}
                      afterCreationChannel={afterChannelCreation}
                      setCurrentChannelId={setCurrentChannelId}
                    />
                  )}
                </LoadingOverlay>
              </div>
            </div>
          )}
      </div>
    </>
  );
};

export default MessageSection;
