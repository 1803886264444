import './index.css';
import Heading from './components/Heading';
import Message from './components/Message';
import StaffNotes from './components/StaffNotes';
import Appointments from './components/Appointments';
import LoadingOverlay from 'react-loading-overlay-ts';
import { XIcon } from '@heroicons/react/solid';
import { ReactComponent as EyeSVG } from 'assets/icons/eye.svg';
import MeetingDetails from './components/MeetingDetails';
import { useEffect, useState } from 'react';
import TaskApi from 'api/TaskAPI';
import Result from './components/Result';
import { useSelector, RootStateOrAny } from 'react-redux';
import Takeover from './components/Takeover';
import { toast } from 'react-toastify';
import RequestDetails from './components/RequestDetails';
import { ViewChart } from 'utils/ViewChart';
import ContactButtons from 'components/Admin/Patient/PatientInfo/ContactButtons';
import PatientAPI from 'api/PatientAPI';
import IntakeItems from './components/IntakeItems';
import BasicInfoSection from './components/BasicInfoSection';
import PharmacyInfo from './components/PharmacyInfo';
import { PopoverTaskProps } from './popover.types';
import LinkToTask from './components/LinkToPreConsultTask';
import moment from 'moment';
import { stringify } from 'querystring';
import { IntakeItem } from './components/IntakeItems/IntakeItems.types';
import * as Sentry from '@sentry/react';

const PRE_CONSULT = 'Pre-consult';
const ONBOARDING = 'Onboarding';
const POST_CONSULT = 'Post-consult';
const APPOINTMENT = 'Appointment';
const APPOINTMENT_MISSED = 'Appointment: Missed';
const CANCELLED = 'cancelled';
const REQUESTS = 'Requests';
const FRONT_DESK = 'Front desk';
const RESULTS = 'Results';
const MESSAGES = 'Messages';

const taskCategories = [PRE_CONSULT, POST_CONSULT, APPOINTMENT, APPOINTMENT_MISSED, ONBOARDING];
const appointmentCategories = [APPOINTMENT, APPOINTMENT_MISSED];

const requestCategories = [REQUESTS, APPOINTMENT];
const usersHasPermissionOnboardingBlocks = ['Physician', 'Medical Assistant', 'Nurse Practitioner'];
const usersHasPermissionPostConsultLink = ['Physician', 'Medical Assistant'];
const usersHasPermissionOnboardingBlocksMeetingDetails = [
  'Admin',
  'Physician',
  'Medical Assistant',
  'Nurse Practitioner',
];

export const isUserHasPermission = (UsersList: string[], userType: string) => {
  return UsersList.includes(userType);
};

const Popover: React.FC<PopoverTaskProps> = ({
  taskId,
  onCancel,
  onCanelComplete,
  onClickLinkToPreConsult,
  onSuccessCancel,
}) => {
  const [taskDetails, setTaskDetails] = useState<any>({ category: '' });
  const [popOverHeaderProps, setPopoverHeaderProps] = useState<any>({});
  const [staffNotes, setStaffNotes] = useState([] as any);
  const [appointmentInformation, setAppointmentInformation] = useState({} as any);
  const [messageInformation, setMessageInformation] = useState([] as any);
  const [upComingAppointment, setUpComingAppointment] = useState([] as any);
  const [patientDetails, setPatientDetails] = useState<any>([]);
  const [loading, setLoading] = useState(false);
  const [patientChannelList, setPatientChannelList] = useState([]);
  const userInfo = useSelector((state: RootStateOrAny) => state.userProfile.userDetails);
  const [messageSent, setmessageSent] = useState(false);
  const [markCompleteEnabled, setmarkCompleteEnabled] = useState(false);

  const isPreConsultOrOnboarding = taskDetails.category === PRE_CONSULT || taskDetails.category === ONBOARDING;

  const getPatientDetails = () => {
    PatientAPI.fetchSinglePatient(popOverHeaderProps.patientId).then((res) => {
      const { data } = res.data;
      if (data) {
        setPatientDetails(data);
      }
    });
  };

  const isCategoriesListHasCategory = (CategoriesList: string[], category: string) => {
    return CategoriesList.includes(category);
  };

  useEffect(() => {
    if (popOverHeaderProps.patientId) {
      getPatientDetails();
    }
  }, [popOverHeaderProps]);

  useEffect(() => {
    if (taskId !== '') {
      fetchTaskDetails();
    }
  }, [taskId]);

  const showLinkToPreConsult = () => {
    return (
      isUserHasPermission(usersHasPermissionOnboardingBlocks, userInfo.userType?.name) &&
      isCategoriesListHasCategory([APPOINTMENT], taskDetails?.category) &&
      appointmentInformation?.appointmentStatus !== CANCELLED &&
      taskDetails?.preConsultTaskId
    );
  };

  const fetchTaskDetails = () => {
    setLoading(true);
    TaskApi.fetchSingleTask(taskId)
      .then((res) => {
        const { data, message, errorCode } = res.data;
        if (errorCode) {
          toast.error(message);
          onCancel();
        }

        if (data) {
          setTaskDetails(data);
          console.log(data);
          setPopoverHeaderProps({
            taskId: taskId,
            appointmentId: data.appointmentInfo && data.appointmentInfo._id && data.appointmentInfo._id,
            visitType: data.appointmentInfo && data.appointmentInfo.visitType && data.appointmentInfo.visitType,
            patientName: data?.personalInfo?.firstName + ' ' + data?.personalInfo?.lastName,
            patientProfileUrl: data.personalInfo && data.personalInfo.profileImage && data.personalInfo.profileImage,
            patientId: data.personalInfo && data.personalInfo._id && data.personalInfo._id,
            category: data.category && data.category,
            categoryInfo: data.categoryInfo && data.categoryInfo,
            dueDate: data.dueDate && data.dueDate,
            assignedTo: data.assignedToInfo && data.assignedToInfo,
            intakeItemsInfo: data.intakeItemsInfo,
            status: data.status && data.status,
            plan: data?.personalInfo?.planInfo?.planName,
            fetchTaskDetails: fetchTaskDetails,
            onCancel: onCanelComplete,
            closeModal: onCancel,
            isInteractedPatient: data.isInteractedPatient && data.isInteractedPatient,
            isPendingApproval: data.isPendingApproval && data.isInteractedPatient,
            isReview: data.resultInfo && data.resultInfo.isReviewed && data.resultInfo.isReviewed,
            agingAlertAudience: data.agingAlertAudience,
            visitTypeRequired: data.visitTypeRequired,
          });
          setAppointmentInformation(data.appointmentInfo && data.appointmentInfo);
          setUpComingAppointment(data.upcomingAppointmentInfo && data.upcomingAppointmentInfo);

          toast.success(message);
        }
      })
      .catch((e) => {
        if (e.response?.data?.message) {
          Sentry.captureException(e);
          toast.error(e.response.data.message);
        }
        onCancel();
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const styleWrapperModal = { maxHeight: '85vh' };

  const onChangeIntakeItems = (refreshIntakeItems: IntakeItem[]) => {
    setPopoverHeaderProps((prevValue: any) => ({
      ...prevValue,
      intakeItemsInfo: refreshIntakeItems,
    }));
  };
  const fetchChannellist = () => {
    if (popOverHeaderProps.category === FRONT_DESK) {
      PatientAPI.fetchPatientFrontDeskChannels(popOverHeaderProps.patientId, { fetchFirstMessage: false })
        .then((res) => {
          const { data: resData } = res.data.data;
          if (resData) {
            setPatientChannelList(resData);
          }
        })
        .catch((e) => {
          if (e.response?.data?.message) {
            toast.error(e.response.data.message);
          }
        });
    } else {
      PatientAPI.fetchPatientChannels(popOverHeaderProps.patientId)
        .then((res) => {
          const { data: resData } = res.data;
          if (resData) {
            setPatientChannelList(resData);
          }
        })
        .catch((e) => {
          if (e.response?.data?.message) {
            toast.error(e.response.data.message);
          }
        });
    }
  };

  useEffect(() => {
    if (popOverHeaderProps && popOverHeaderProps.patientId) {
      fetchChannellist();
    }
  }, [popOverHeaderProps]);

  return (
    <>
      <LoadingOverlay active={loading} spinner></LoadingOverlay>
      <div className="flex items-center justify-center inset-0 fixed py-12 z-50">
        <div className="shadow-full w-4/5 m-auto pt-4 relative bg-white">
          <button
            className="rounded-full h-14 w-14 bg-darkBlue flex justify-center items-center absolute -top-6 -right-7 z-10"
            onClick={() => onCanelComplete()}
          >
            <XIcon className="text-white h-6 w-6" />
          </button>

          <div className="overflow-y-auto" style={styleWrapperModal}>
            <div className="px-16">
              <Heading
                headerProps={popOverHeaderProps}
                appointmentInformation={appointmentInformation && appointmentInformation}
                messageSent={messageSent}
                markCompleteEnabled={markCompleteEnabled}
              />
              <div className="my-8 border-t" />
              {taskDetails && taskDetails.category === RESULTS && taskDetails.resultInfo && (
                <>
                  <Result
                    results={taskDetails.resultInfo && taskDetails.resultInfo}
                    patientId={taskDetails.personalInfo && taskDetails.personalInfo._id}
                  />
                  <div className="my-8 border-t" />
                </>
              )}
              {/* {userInfo.userType.name !== 'Admin' && taskDetails && taskDetails.handoverTo && (
                <Takeover
                  taskId={taskId}
                  fetchTaskDetails={fetchTaskDetails}
                  onCancel={onCanelComplete}
                  requestInfo={taskDetails.handoverTo}
                  taskStatus={taskDetails.status}
                />
              )} */}

              {isUserHasPermission(usersHasPermissionOnboardingBlocksMeetingDetails, userInfo.userType?.name) &&
                taskDetails &&
                isCategoriesListHasCategory(taskCategories, taskDetails.category) && (
                  <>
                    {appointmentInformation?._id && (
                      <>
                        <MeetingDetails
                          appointmentInformation={appointmentInformation && appointmentInformation}
                          taskId={taskId}
                          fetchTaskDetails={fetchTaskDetails}
                          userType={userInfo.userType?.name}
                          userId={userInfo._id}
                          taskDetails={taskDetails}
                          channelList={patientChannelList && patientChannelList}
                          category={taskDetails && taskDetails.categoryInfo}
                        />
                        <div className="my-8 border-t" />
                      </>
                    )}
                  </>
                )}

              {isUserHasPermission(usersHasPermissionOnboardingBlocks, userInfo.userType?.name) &&
                taskDetails?.categoryInfo !== 'Appointment: Cancelled' &&
                taskDetails?.categoryInfo !== 'Appointment: Missed' &&
                isCategoriesListHasCategory([APPOINTMENT, POST_CONSULT], taskDetails?.category) &&
                taskDetails?.preConsultTaskId && (
                  <>
                    <LinkToTask
                      taskId={taskDetails.preConsultTaskId}
                      onClickLinkToPreConsult={onClickLinkToPreConsult}
                      fetchTaskDetails={fetchTaskDetails}
                      isOnboardingOrPreConsult
                    />
                    <div className="my-8 border-t" />
                  </>
                )}
              {isUserHasPermission(usersHasPermissionOnboardingBlocks, userInfo.userType?.name) &&
                isPreConsultOrOnboarding && (
                  <>
                    <div className="flex justify-end">
                      <ContactButtons
                        patientDetails={patientDetails}
                        RouteViewButtonText="View chart"
                        onClickRouteViewButton={() => ViewChart(popOverHeaderProps.patientId)}
                        RouteViewImagineSVG={<EyeSVG className="w-5 opacity-75" />}
                      />
                    </div>
                    <IntakeItems
                      intakeItemsList={taskDetails.intakeItemsInfo}
                      taskId={taskId}
                      taskStatus={taskDetails.status}
                      onChangeIntakeItems={onChangeIntakeItems}
                    />
                    <div className="my-8 border-t" />
                  </>
                )}

              {taskDetails &&
                isCategoriesListHasCategory(requestCategories, taskDetails.category) &&
                taskDetails.requestInfo &&
                taskDetails.requestInfo.length !== 0 && (
                  <RequestDetails
                    requestedDetails={taskDetails && taskDetails.requestInfo}
                    fetchTaskDetails={fetchTaskDetails}
                    category={taskDetails && taskDetails.category}
                  />
                )}
              <Message
                channelList={patientChannelList && patientChannelList}
                taskId={taskId}
                category={taskDetails && taskDetails.category}
                patientId={taskDetails.personalInfo && taskDetails.personalInfo._id}
                assignedTo={taskDetails && taskDetails.assignedToInfo}
                defaultChannelId={taskDetails && taskDetails.channelId}
                status={taskDetails.status}
                fetchChannelList={fetchChannellist}
                setmessageSent={setmessageSent}
                setmarkCompleteEnabled={setmarkCompleteEnabled}
              />
            </div>
            <div className="bg-lightGreyBackground ">
              <div className="px-16">
                {taskDetails && taskDetails.category !== FRONT_DESK && (
                  <>
                    <StaffNotes
                      taskId={taskId}
                      fetchTaskDetails={fetchTaskDetails}
                      category={taskDetails && taskDetails.category}
                      assignedTo={taskDetails && taskDetails.assignedToInfo}
                      patientId={taskDetails.personalInfo && taskDetails.personalInfo._id}
                      status={taskDetails.status}
                    />
                    <div className="my-8 border-t" />
                  </>
                )}
                {isPreConsultOrOnboarding && (
                  <>
                    <BasicInfoSection patientDetails={patientDetails} />
                    <div className="mb-8 border-t" />
                    {taskDetails.pharmacyInfo && (
                      <>
                        <PharmacyInfo pharmacyDetails={taskDetails.pharmacyInfo} />
                        <div className="mb-8 border-t" />
                      </>
                    )}
                  </>
                )}
                <Appointments
                  upComingAppointment={upComingAppointment}
                  patientId={taskDetails.personalInfo && taskDetails.personalInfo._id}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="opacity-50 fixed inset-0 z-40 bg-darkBlue" />
    </>
  );
};

export default Popover;
