import { useState, useCallback, useEffect, useRef, KeyboardEventHandler } from 'react';
import { useClickAway } from 'react-use';
import Button from 'components/common/Button';
import classNames from 'classnames';
import Dropdown from 'components/common/Dropdown';
import { ReactComponent as Check } from 'assets/icons/check.svg';
import { ReactComponent as Lighting } from 'assets/icons/lighting.svg';
import MessageTemplateAPI from 'api/MessageTemplateAPI';
import InfiniteScroll from 'react-infinite-scroll-component';
import { debounce } from 'lodash';
import { toast } from 'react-toastify';
import socket from 'socket/socket';
import TaskApi from 'api/TaskAPI';
import ChannelApi from 'api/ChannelApi';
import socketFrontDesk from 'socket/socketFrontDesk';
import * as Sentry from '@sentry/react';
import { ChannelData } from 'redux/channels/interfaces';
import _ from 'lodash';

interface IFormProps {
  allowToPickChannel?: boolean;
  channelList?: any;
  onSendMessage: (id: string, messsage: string, buttons: any) => void;
  markSeenMessage: () => void;
  messsageSend?: boolean;
  patientId?: string;
  taskId?: string;
  doctorId?: string;
  channelCategory?: string;
  defaultChannelId?: string;
  afterCreationChannel?: any;
  placeholder?: string;
  buttonText?: string;
  setCurrentChannelId?: any;
}
const MessagePatientForm: React.FC<IFormProps> = ({
  allowToPickChannel,
  channelList,
  onSendMessage,
  markSeenMessage,
  messsageSend,
  patientId,
  taskId,
  doctorId,
  defaultChannelId,
  channelCategory,
  afterCreationChannel,
  placeholder,
  buttonText,
  setCurrentChannelId,
}) => {
  const [showTemplates, setShowTemplates] = useState(false);
  const [templateList, setTemplateList] = useState([] as any);
  const [loadMoreTemplates, setLoadMoreTemplates] = useState(true);
  const [templateTotalCount, setTemplateTotalCount] = useState(0);
  const [templatePage, setTemplatePage] = useState(0);
  const [messageText, setMessageText] = useState('');
  const [searchValue, setSearchValue] = useState('');
  const [showTopics, setShowTopics] = useState(false);
  const [openAdditionalChannels, setOpenAdditionalChannels] = useState(false);
  const [topic, setTopic] = useState<any>();
  const [messageButton, setMessageButton] = useState<any>();
  const [channelTopics, setChannelTopics] = useState<any>();
  const [channelName, setChannelName] = useState<any>('');
  const [disabled, setDisabled] = useState(true);
  const [disabledAddNewChannel, setDisabledAddNewChannel] = useState(true);
  const [selectedChannelId, setSelectedChannelId] = useState('');

  const templatesRef = useRef(null);
  const topicsRef = useRef<HTMLDivElement>(null);

  const replayTemplatesButton = 'replayTemplatesButton';
  const postInButton = 'postInButton';

  const addNewChannelClasses = classNames('mt-4 self-end btn-primary btn', disabledAddNewChannel && 'opacity-50');

  const channelListDropdown = [
    { value: 'General health', label: 'General health' },
    {
      value: 'Aches & Pains (Head, ear, back, Mouth etc.)',
      label: 'Aches & Pains (Head, ear, back Mouth etc.)',
    },
    { value: 'Allergies', label: 'Allergies' },
    { value: 'Annual Checkup', label: 'Annual Checkup' },
    {
      value: 'Anxiety / Depression (mild to moderate)',
      label: 'Anxiety / Depression (mild to moderate)',
    },
    { value: 'Asthma', label: 'Asthma' },
    { value: 'Bacterial Infections', label: 'Bacterial Infections' },
    { value: 'Benign Prostatic Hyperplasia', label: 'Benign Prostatic Hyperplasia' },
    { value: 'Cardiac Disorders', label: 'Cardiac Disorders' },
    { value: 'Constipation', label: 'Constipation' },
    { value: 'Coronary Artery Disease (CAD)', label: 'Coronary Artery Disease (CAD)' },
    { value: 'Deep Vein Thrombosis (DVT)', label: 'Deep Vein Thrombosis (DVT)' },
    { value: 'Dental Pain / Discomfort', label: 'Dental Pain / Discomfort' },
    { value: 'Diabetes', label: 'Diabetes' },
    { value: 'Dizziness / Imbalance', label: 'Dizziness / Imbalance' },
    {
      value: 'Ear discomfort (Aches, Ringing, etc.)',
      label: 'Ear discomfort (Aches, Ringing, etc.)',
    },
    {
      value: 'Eye discomfort (infection, blurry vision, etc.)',
      label: 'Eye discomfort (infection, blurry vision, etc.)',
    },
    { value: 'Fatigue', label: 'Fatigue' },
    { value: 'GERD', label: 'GERD' },
    { value: 'Genital Pain', label: 'Genital Pain' },
    { value: 'Hemorrhoids', label: 'Hemorrhoids' },
    { value: 'Hives / Rashes', label: 'Hives / Rashes' },
    { value: 'Hormone Evaluation', label: 'Hormone Evaluation' },
    { value: 'Hypertension', label: 'Hypertension' },
    { value: 'Ingrown Hair / Infection', label: 'Ingrown Hair / Infection' },
    { value: 'Lab Tests', label: 'Lab Tests' },
    { value: 'Lifestyle Improvement', label: 'Lifestyle Improvement' },
    {
      value: 'Muscle Discomfort (strains, sprains & cramps)',
      label: 'Muscle Discomfort (strains, sprains & cramps)',
    },
    { value: 'Nasal / Sinus Discomfort', label: 'Nasal / Sinus Discomfort' },
    { value: 'Neurological Disorders', label: 'Neurological Disorders' },
    { value: 'Osteoarthritis', label: 'Osteoarthritis' },
    { value: 'Osteoporosis', label: 'Osteoporosis' },
    { value: 'Chronic care', label: 'Chronic care' },
    { value: 'Primary care', label: 'Primary care' },
    { value: 'Urgent care', label: 'Urgent care' },
    { value: 'Prescription Refills', label: 'Prescription Refills' },
    { value: 'Rectal Bleeding', label: 'Rectal Bleeding' },
    {
      value: 'Sexual Health (UTI, Yeast Infection, STDs, BV, Etc.)',
      label: 'Sexual Health (UTI, Yeast Infection, STDs, BV, Etc.)',
    },
    { value: 'Sleep', label: 'Sleep' },
    { value: 'Sore Throat', label: 'Sore Throat' },
    { value: 'Stomach Pain (Ulcers)', label: 'Stomach Pain (Ulcers)' },
    { value: 'Sunburn', label: 'Sunburn' },
    { value: 'Thyroid Disease', label: 'Thyroid Disease' },
    { value: 'Toenail Fungus', label: 'Toenail Fungus' },
  ];

  useClickAway(templatesRef, (e: Event) => {
    if ((e.target as HTMLButtonElement).id !== replayTemplatesButton) {
      setShowTemplates(false);
    }
    setDisabledAddNewChannel(true);
  });

  useClickAway(topicsRef, (e: Event) => {
    if ((e.target as HTMLButtonElement).id !== postInButton) {
      setShowTopics(false);
    }
  });

  useEffect(() => {
    if (messsageSend) {
      setMessageText('');
      setMessageButton([]);
    }
  }, [messsageSend]);

  const getPatientMessageTemplates = (payload: any) => {
    MessageTemplateAPI.fetchPatientMessageTemplates(payload).then((res) => {
      const { data } = res.data;

      if (data.length !== 0) {
        const list: any = showTemplates ? [...templateList] : [];
        data.map((eachItem: any) => {
          list.push({ text: eachItem.message, shortcode: eachItem.shortCode, id: eachItem._id });
        });
        setTemplateList(list);
        setTemplateTotalCount(res.data.info.totalCount);
        if (list.length === res.data.info.totalCount) {
          setLoadMoreTemplates(false);
        } else {
          setLoadMoreTemplates(true);
        }
      } else {
        setTemplateTotalCount(res.data.info.totalCount);
        setTemplateList([]);
        setLoadMoreTemplates(false);
      }
    });
  };

  const onClickReplayTemplates = () => {
    setTemplateList([]);
    if (!showTemplates) {
      const data = {
        limit: 10,
        pageNo: 0,
        status: 'active',
        searchKey: searchValue,
      };
      setTemplatePage(0);
      getPatientMessageTemplates(data);
    } else {
      setTemplateList([]);
      setTemplateTotalCount(0);
      setTemplatePage(0);
    }
    setShowTemplates(!showTemplates);
  };

  const onTemplateListClick = (text: string, id: string) => {
    setShowTemplates(false);
    const params = {
      type: 'Message',
      patientId: patientId,
      doctorId: doctorId,
    };
    MessageTemplateAPI.fetchMessage(id, params)
      .then((res) => {
        const { data } = res.data;
        setMessageText(data.message);
        setDisabled(!data.message.trim());
        setMessageButton(data.buttons);
      })
      .catch((e) => {
        toast.error(e.response.data.message);
      });
  };

  const loadMore = () => {
    const data = {
      limit: 10,
      pageNo: templatePage + 1,
      status: 'active',
      searchKey: searchValue,
    };
    const count: number = templatePage + 1;
    setTemplatePage(count);
    getPatientMessageTemplates(data);
  };

  const onSearchTemplate = (value: string) => {
    setTemplatePage(0);
    const data = {
      limit: 10,
      pageNo: 0,
      status: 'active',
      searchKey: value,
    };
    setTemplateList([]);
    getPatientMessageTemplates(data);
  };

  const createNewChannel = () => {
    const data = {
      patientId: patientId,
      channelTitle: channelName,
    };
    setDisabledAddNewChannel(true);
    ChannelApi.createChannel(data)
      .then((res) => {
        console.log('repsonse is---------->', res);
        setChannelName('');
        setShowTopics(!showTopics);
        channelList.push({
          channelTitle: res.data.data.channelTitle,
          channelId: res.data.data._id,
        });
        setCurrentChannelId(res.data.data._id);
        setTopic({
          label: res.data.data.channelTitle,
          value: res.data.data._id,
        });
        setOpenAdditionalChannels(false);
        afterCreationChannel();
        onChannelChange(res.data.data._id);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const debounceSearch = useCallback(debounce(onSearchTemplate, 1500), []);
  useEffect(() => {
    if (searchValue) {
      debounceSearch(searchValue);
    }
  }, [searchValue]);

  useEffect(() => {
    if (channelList) {
      const channelOption: any = [];
      channelList.map((eachItem: any) => {
        channelOption.push({
          label: eachItem.channelTitle ? eachItem.channelTitle : eachItem.frontDeskRequestType,
          value: eachItem.channelId,
        });
        if (defaultChannelId) {
          if (eachItem.channelId === defaultChannelId) {
            setTopic({
              label: eachItem.channelTitle ? eachItem.channelTitle : eachItem.frontDeskRequestType,
              value: eachItem.channelId,
            });
          } else {
            console.log('pass');
          }
        } else {
          if (eachItem.channelTitle === 'General health') {
            setTopic({
              label: eachItem.channelTitle ? eachItem.channelTitle : eachItem.frontDeskRequestType,
              value: eachItem.channelId,
            });
          }
        }
      });
      setChannelTopics(channelOption);
    }
  }, [channelList, defaultChannelId]);

  useEffect(() => {
    const defaultSelectedChannelId =
      channelCategory === 'frontdesk'
        ? channelList?.length !== 0 && channelList[0].channelId
        : channelList?.find(
            (channel: ChannelData) => channel.channelTitle.toLowerCase() === 'General health'.toLowerCase(),
          )?.channelId;
    if (
      taskId &&
      channelList?.length !== 0 &&
      _.isEmpty(channelList?.find((item: any) => item.channelId === defaultChannelId)) &&
      (!selectedChannelId || (selectedChannelId && selectedChannelId !== defaultSelectedChannelId))
    ) {
      TaskApi.changeTaskChannel(taskId, { channelId: defaultSelectedChannelId })
        .then((res) => {
          setSelectedChannelId(defaultSelectedChannelId);
          setCurrentChannelId(defaultSelectedChannelId);
        })
        .catch((err) => {
          console.log(err);
        });
    }
  }, [defaultChannelId]);

  useEffect(() => {
    if (_.isEmpty(topic) && channelList?.length !== 0) {
      const defaultTopic = channelList?.find(
        (channel: ChannelData) => channel.channelTitle.toLowerCase() === 'General health'.toLowerCase(),
      );
      setTopic({
        label: defaultTopic?.channelTitle,
        value: defaultTopic?.channelId,
      });
    }
    if (topic) {
      // setCurrentChannelId(topic.value);
      // socket.on('channels', (data) => {
      //   setCurrentChannelId(topic.value);
      // });
      try {
        if (topic.value)
          if (channelCategory !== 'frontdesk') {
            socket.emit('joinRoom', topic.value, (data: any) => {
              console.log('=======joined channel==========');
            });
          } else {
            socketFrontDesk.emit('joinRoom', topic.value, (data: any) => {
              console.log('=======joined channel==========');
            });
          }
      } catch (e) {
        Sentry.captureException(e);
        console.log('error');
      }
    }
  }, [topic]);

  useEffect(() => {
    if (openAdditionalChannels && topicsRef.current !== null) {
      topicsRef.current.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
      setDisabledAddNewChannel(true);
    }
  }, [openAdditionalChannels]);

  const handleKeyDownMessages: KeyboardEventHandler<HTMLTextAreaElement> = (e) => {
    if (e.key === '/') {
      setShowTemplates(true);
    }
  };

  const onChangeText = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    const newVal = e.target.value;
    setDisabled(!newVal.trim());
    setMessageText(newVal);
  };

  const onClickMessagePatient = () => {
    setDisabled(true);
    setMessageText('');
    messageText?.length && onSendMessage(topic && topic.value, messageText, messageButton);
  };

  const onChannelChange = (t: any) => {
    if (t.value) {
      setTopic(t);
      setShowTopics(false);
      setCurrentChannelId(t.value);
    }
    TaskApi.changeTaskChannel(taskId, { channelId: t.value ? t.value : t }).catch((err) => {
      console.log(err);
    });
  };

  return (
    <>
      <div className="relative">
        <div className="flex flex-col">
          <textarea
            className="border-gray-200 border rounded-t-xl px-4 py-2 w-full h-20"
            placeholder={placeholder || 'Free-form message to patient…'}
            value={messageText}
            onChange={onChangeText}
            onFocus={(e) => {
              e.stopPropagation();
              markSeenMessage();
            }}
            onKeyDown={handleKeyDownMessages}
          ></textarea>
          <div className="border-gray-200 border border-t-0 rounded-b-xl flex flex-col sm:flex-row justify-between p-1">
            <Button
              onClick={() => onClickReplayTemplates()}
              ownClasses="font-normal text-myPlanLightGrey"
              id={replayTemplatesButton}
              type="depressed"
            >
              <Lighting className="w-4 mr-2 mt-1 cursor-pointer" />
              Reply templates
            </Button>
            {allowToPickChannel && (
              <span className="ml-auto flex items-center">
                Post in
                <Button
                  id={postInButton}
                  onClick={() => {
                    setShowTopics(!showTopics);
                  }}
                  ownClasses="pl-2 font-normal text-myPlanLightGrey"
                  type="depressed"
                >
                  {topic && topic.label}
                </Button>
              </span>
            )}
            <Button ownClasses="mr-2" type="primary" onClick={onClickMessagePatient} disabled={disabled}>
              {buttonText || 'Message patient'}
            </Button>
          </div>
        </div>
        {showTemplates && (
          <div
            className="absolute bg-lightGrey top-0 right-0 w-full h-60 transform -translate-y-2/3 rounded-lg rounded-b-none p-4 shadow-lg"
            ref={templatesRef}
          >
            <input
              placeholder="Search templates"
              className="search-input"
              type="search"
              onChange={(e) => setSearchValue(e.target.value)}
              autoFocus
            />
            <div className="flex justify-between my-2">
              <h4 className="font-bold text-sm text-dashboardPrimaryGrey">Template</h4>
              <h4 className="font-bold text-sm text-dashboardPrimaryGrey">Shortcode</h4>
            </div>
            <div className="h-36 overflow-y-auto" id="scrollTemplate">
              <InfiniteScroll
                dataLength={templateTotalCount}
                next={loadMore}
                hasMore={loadMoreTemplates}
                loader={<div className="loader"> Loading... </div>}
                scrollableTarget="scrollTemplate"
              >
                {templateList.map((template: any) => (
                  <div
                    key={template.shortcode}
                    className="flex justify-between mb-2"
                    onClick={() => onTemplateListClick(template.text, template.id)}
                  >
                    <span className="truncate cursor-pointer w-5/6 text-sm text-dashboardPrimaryGrey">
                      {template.text}
                    </span>
                    <span className="text-sm text-darkBlue font-bold">{template.shortcode}</span>
                  </div>
                ))}
              </InfiniteScroll>
            </div>
          </div>
        )}
        {showTopics && (
          <div
            ref={topicsRef}
            className="absolute flex bg-lightGrey top-0 overflow-y-scroll right-0 w-full h-60 transform -translate-y-2/3 rounded-lg p-8"
          >
            <div className="w-1/2">
              <div className="mb-4">
                <h4 className="font-bold text-sm md:text-base 2xl:text-sm text-dashboardPrimaryGrey">Select channel</h4>
              </div>
              <div className="w-1/2">
                {channelTopics.map((t: any) => (
                  <div key={t.value} onClick={() => onChannelChange(t)} className="mb-2 flex items-center">
                    {topic.value === t.value && <Check className="w-4 mr-2" />}
                    <span
                      className={classNames(
                        'truncate cursor-pointer text-sm md:text-base 2xl:text-sm',
                        topic.value === t.value ? 'text-darkBlue font-semibold' : 'text-dashboardPrimaryGrey',
                      )}
                    >
                      {t.label}
                    </span>
                  </div>
                ))}
                {channelCategory !== 'frontdesk' && (
                  <span
                    onClick={() => setOpenAdditionalChannels(!openAdditionalChannels)}
                    className="underline cursor-pointer"
                  >
                    {openAdditionalChannels ? 'Cancel' : '+ New'}
                  </span>
                )}
              </div>
            </div>
            {openAdditionalChannels && (
              <div className="w-1/2 px-8 border-l border-l-purple-500 relative">
                <h4 className="mb-4 font-bold text-sm md:text-base 2xl:text-xl text-dashboardPrimaryGrey">
                  Create new channel
                </h4>
                <div className="flex flex-col" style={{ width: 'auto', minWidth: '15em' }}>
                  <Dropdown
                    placeholder="Select channel name"
                    styles={{ menuPortal: (base) => ({ ...base, zIndex: 9999 }) }}
                    closeMenuOnScroll={(e) => e.target !== document.querySelector('.msg-channel-dropdown__menu-list')}
                    menuShouldScrollIntoView={false}
                    menuPortalTarget={document.querySelector('body') as HTMLElement}
                    onChange={(e: any) => {
                      const channelExists = channelList.some((item: any) => {
                        return item.channelTitle === e.value.trim();
                      });
                      if (channelExists) {
                        setDisabledAddNewChannel(true);
                        return;
                      }

                      setDisabledAddNewChannel(!e.value.trim());
                      setChannelName(e.value);
                    }}
                    ownClasses="border border-lightBorder rounded-lg w-full mb-2"
                    options={channelListDropdown}
                    classNamePrefix="msg-channel-dropdown"
                  />
                  <div className="text-gray-400">Select from list or create a new one</div>
                  <button
                    className={addNewChannelClasses}
                    disabled={disabledAddNewChannel}
                    onClick={() => createNewChannel()}
                  >
                    Add
                  </button>
                </div>
              </div>
            )}
          </div>
        )}
      </div>
    </>
  );
};

export default MessagePatientForm;
