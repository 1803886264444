import { ReactComponent as DashboardSvg } from '../../assets/icons/sidebar/dashboard.svg';
import { ReactComponent as PlusSvg } from '../../assets/icons/sidebar/plus.svg';
import { ReactComponent as AdminSvg } from '../../assets/icons/sidebar/admin.svg';
import { ReactComponent as PhysiciansSvg } from '../../assets/icons/sidebar/physicians.svg';
import { ReactComponent as PatientsSvg } from '../../assets/icons/sidebar/patients.svg';
import { ReactComponent as AppointmentsSvg } from '../../assets/icons/sidebar/appointments.svg';
import { ReactComponent as TasksSvg } from '../../assets/icons/sidebar/tasks.svg';
import { ReactComponent as TemplatesSvg } from '../../assets/icons/sidebar/templates.svg';
import { ReactComponent as ArticlesSvg } from '../../assets/icons/sidebar/articles.svg';
import { ReactComponent as ArrowRight } from '../../assets/icons/sidebar/arrow-right.svg';
import { ReactComponent as Logo } from 'assets/images/logo.svg';
import classNames from 'classnames';
import Button from 'components/common/Button';
import { NavLink, useHistory } from 'react-router-dom';
import { useEffect, useState } from 'react';
import { hasAccessToModule } from 'auth/authorization';
import { RootStateOrAny, useSelector } from 'react-redux';
import moment from 'moment';
import { widget } from 'utils/markerIo';
import packageInfo from '../../../package.json';
const Physician = 'Physician';
const MA = 'Medical Assistant';
const Admin = 'Admin';
const CustomerServiceRep = 'Customer Service Rep';

const SlimSidebar: React.FC<{ toggle: boolean; setToggle: () => void }> = ({ toggle, setToggle }) => {
  const [menuItems, setMenuItems] = useState([] as any);
  const [time, setTime] = useState('');
  const userInfo = useSelector((state: RootStateOrAny) => state.userProfile.userDetails);
  const urgentTaskCount = useSelector((state: RootStateOrAny) => state.tasks.urgentTaskCount);

  useEffect(() => {
    if (userInfo.userRoleInfo.pagePermissions) {
      const items = [];

      hasAccessToModule(userInfo.userRoleInfo.pagePermissions, ['TASK_LIST_VIEW']) &&
        items.push({
          path: '/dashboard/tasks',
          icon: <TasksSvg className="sidebar-link__icon group-hover:text-white" />,
          text: 'Tasks',
        });

      [Physician, MA, Admin, CustomerServiceRep].includes(userInfo.userType.name) &&
        hasAccessToModule(userInfo.userRoleInfo.pagePermissions, ['APPOINTMENT_CALENDAR']) &&
        items.push({
          path: '/dashboard/appointments',
          icon: <AppointmentsSvg className="sidebar-link__icon group-hover:text-white" />,
          text: 'Appointments',
        });

      hasAccessToModule(userInfo.userRoleInfo.pagePermissions, ['PATIENT_LIST_VIEW']) &&
        items.push({
          path: '/dashboard/patients',
          icon: <PatientsSvg className="sidebar-link__icon group-hover:text-white" />,
          text: 'Patients',
        });
      hasAccessToModule(userInfo.userRoleInfo.pagePermissions, ['ARTICLES_LIST_VIEW']) &&
        items.push({
          path: '/dashboard/articles',
          icon: <ArticlesSvg className="sidebar-link__icon group-hover:text-white" />,
          text: 'Articles',
        });

      hasAccessToModule(userInfo.userRoleInfo.pagePermissions, [], userInfo.userType.name) &&
        items.push({
          path: '/dashboard/admin',
          icon: <AdminSvg className="sidebar-link__icon group-hover:text-white" />,
          text: 'Admin',
        });

      setMenuItems(items);
    }
  }, [userInfo]);
  const menuItemsL = [
    {
      path: '/physicians',
      icon: <PhysiciansSvg className="sidebar-link__icon group-hover:text-white" />,
      text: 'Physicians',
    },
    {
      path: '/templates',
      icon: <TemplatesSvg className="sidebar-link__icon group-hover:text-white" />,
      text: 'Templates',
    },
  ];

  const sidebarClasses = classNames(
    'transition-all z-20 duration-500 flex flex-col items-center justify-between bg-white fixed h-screen overflow-y-auto top-0',
    toggle ? 'w-24 px-3' : 'w-sb',
  );
  const dashboardBtnClasses = classNames(
    'transition-all duration-500 flex flex-col font-normal py-2',
    toggle ? 'px-2 py-3 mt-3 mb-4 mx-auto rounded-3xl' : 'mb-12 px-12 2xl:px-16 2xl:py-3 rounded-full',
  );

  const btnTextStyle = classNames(
    'transition-all duration-500 text-white',
    toggle ? 'dashboard-label mt-1' : 'text-xl',
  );
  const history = useHistory();

  const refreshTime = () => {
    const timeZone = userInfo?.timezone || moment.tz.guess();
    const dateString = new Date().toLocaleString('en-US', { timeZone });
    const formattedString = moment(dateString).format('hh:mma');
    const abr = moment.tz(timeZone).format('z');

    setTime(formattedString + ' ' + abr);
  };

  useEffect(() => {
    const timer = setInterval(refreshTime, 1000);
    return () => {
      clearInterval(timer);
    };
  }, [userInfo?.timezone]);

  useEffect(() => {
    widget;
  }, []);

  return (
    <div className={sidebarClasses}>
      <div className={classNames('flex flex-col', { 'items-center': toggle })}>
        {toggle ? (
          <PlusSvg onClick={() => history.push('/')} className="my-11 w-11 h-11" />
        ) : (
          <Logo onClick={() => history.push('/')} className="mx-auto cursor-pointer my-11 w-36" />
        )}
        <Button type="primary" ownClasses={dashboardBtnClasses} onClick={() => history.push('/')}>
          {toggle && <DashboardSvg className="w-5 h-5" />}
          <span className={btnTextStyle}>Dashboard</span>
        </Button>
        <nav className="px-2">
          {menuItems &&
            menuItems.map((el: any) => (
              <NavLink
                className="flex flex-row items-center group mb-6 text-xl text-darkBlue"
                key={el.path}
                to={`${el.path}`}
                activeClassName="sidebar-link-active"
              >
                <div className="icon relative p-3 bg-gray-100 rounded-2xl transition duration-300 group-hover:bg-green">
                  {el.path === '/dashboard/tasks' && (
                    <div>
                      {urgentTaskCount > 0 ? (
                        <span className="absolute -top-3.5  h-7 mx-5 px-2.5 py-1 bg-red text-sm rounded-full">
                          <div className="relative text-center text-white">{urgentTaskCount}</div>
                        </span>
                      ) : null}
                    </div>
                  )}
                  {el.icon}
                </div>
                {!toggle && <span className="mx-5 text-darkBlue">{el.text}</span>}
              </NavLink>
            ))}
        </nav>
      </div>
      <div className="my-auto">
        {time && <p className=" my-4 text-center text-tableLightGrey">Current time: {time}</p>}
        <button
          type="button"
          onClick={setToggle}
          className={classNames('flex flex-row items-center p-3 mx-auto mb-4 bg-gray-100 text-grey', {
            'text-xl px-5 py-2.5': !toggle,
          })}
        >
          <ArrowRight className={classNames('m-auto h-5.5', { 'transform rotate-180 mr-3.5': !toggle })} />
          {!toggle && <span className="mx-1">Slim nav</span>}
        </button>
        <p className="text-center text-tableLightGrey mb-12">v{packageInfo.version}</p>
      </div>
    </div>
  );
};

export default SlimSidebar;
